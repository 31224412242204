import { Col, Container, Row } from 'reactstrap'
import { isNil } from 'lodash'
import { MenuItem } from '@material-ui/core'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { ARCHIVE_GROUP_SETTING } from 'constants/archiveGroup'
import { DATE_TO } from 'constants/inputTypes'
import { I18N } from 'constants/props'
import { StyledDatePicker, StyledSelect } from 'generics/StyledFormComponents'
import Search from 'generics/Search'
import Translation from 'generics/Translation'
import useFormValidation from 'custom_hooks/useFormValidation'

import styles from './FormGroupSearch.scss'

/**
 * FormFileInvitation component
 */
const FormGroupSearch = ({ i18n, initialValues, onSearch }) => {
  const { validator } = useFormValidation({ i18n })
  const [state, setState] = useState(
    !initialValues
      ? {
          endDate: null,
          error: null,
          searchCriteria: null,
          startDate: null,
          ...ARCHIVE_GROUP_SETTING,
        }
      : { ...initialValues },
  )

  const handleChangeField = (fieldName, value) => {
    const { endDate, searchCriteria, isArchive, startDate } = { ...state, [fieldName]: value }

    const error = validator.validate(
      DATE_TO,
      startDate ? new Date(startDate) : null,
      endDate ? new Date(endDate) : null,
    )

    if (isNil(error)) {
      onSearch({ endDate, isArchive, searchCriteria, startDate })
    }

    setState({ endDate, isArchive, error, searchCriteria, startDate })
  }

  const { endDate, error, startDate } = state

  return (
    <Container className={styles.container} fluid>
      <div className={styles.fields}>
        <Row>
          <Col xs="12" md="4">
            <Search
              className={styles.textfield}
              floatingLabelText={i18n.pageAdministration.formGroupSearch.labelGroupName}
              onChange={value => handleChangeField('searchCriteria', value)}
            />
          </Col>
          <Col xs="12" md="3">
            <StyledDatePicker
              ariaLabel={`${i18n.pageAdministration.formGroupSearch.date.startDate} date container.`}
              name="startDate"
              onChange={handleChangeField}
              title={i18n.pageAdministration.formGroupSearch.date.startDate}
              value={startDate}
            />
          </Col>
          <Col xs="12" md="3">
            <StyledDatePicker
              ariaLabel={`${i18n.pageAdministration.formGroupSearch.date.endDate} date container.`}
              name="endDate"
              onChange={handleChangeField}
              title={i18n.pageAdministration.formGroupSearch.date.endDate}
              value={endDate}
            />
          </Col>
          <Col xs="12" md="2">
            <StyledSelect
              // ariaLabel={`${i18n.generics.search.filter.label} dropdown list. ${filterOptions.find((opt) => opt.id === filter.filterBy).label} selected.`}
              color="primary"
              formControlClassName={classnames(styles.filter)}
              fullWidth
              iconClassName={styles.icon}
              label={i18n.pageAdministration.formGroupSearch.labelShowArchiveFilter}
              name="isArchive"
              onChange={e => handleChangeField('isArchive', e.target.value)}
              value={state.isArchive}
            >
              <MenuItem key={i18n.pageAdministration.formGroupSearch.labelAllGroups} value="all">
                {i18n.pageAdministration.formGroupSearch.labelAllGroups}
              </MenuItem>
              <MenuItem key={i18n.pageAdministration.formGroupSearch.labelUnarchiveGroups} value="unarchive">
                {i18n.pageAdministration.formGroupSearch.labelUnarchiveGroups}
              </MenuItem>
              <MenuItem key={i18n.pageAdministration.formGroupSearch.labelArchiveGroups} value="archive">
                {i18n.pageAdministration.formGroupSearch.labelArchiveGroups}
              </MenuItem>
            </StyledSelect>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md={{ size: 6, offset: 6 }}>
            <span className={styles.error} title={i18n.pageAdministration.formGroupSearch.titleError}>
              {error}
            </span>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

FormGroupSearch.propTypes = {
  i18n: I18N.isRequired,
  initialValues: PropTypes.shape({}),
  onSearch: PropTypes.func.isRequired,
}

FormGroupSearch.defaultProps = {
  initialValues: null,
}

export default Translation(FormGroupSearch, ['generics', 'pageAdministration'])
