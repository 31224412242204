import { pickHTMLProps } from 'pick-react-known-prop'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { ICON } from 'constants/props'
import Icon from 'generics/Icon'

import styles from './InformationCardHeader.scss'

const InformationCardHeader = props => {
  const { category, categoryColor, className, handleClick, icon: iconProp, title, titleClassName, ...rest } = props

  let icon

  switch (true) {
    case React.isValidElement(iconProp):
      icon = iconProp
      break

    case iconProp?.constructor === String:
      icon = <Icon.Stroke7 name={iconProp} />
      break

    case iconProp?.constructor === Object:
      icon = <Icon.Stroke7 {...iconProp} />
      break

    default:
      icon = null
      break
  }

  return (
    <div className={classnames(styles.header, className)} {...pickHTMLProps(rest)}>
      {icon && (
        <div
          className={styles.header__icon}
          style={{
            color: categoryColor,
            stroke: categoryColor,
          }}
        >
          {icon}
        </div>
      )}

      {(category || title) && (
        <div className={styles['header__title-wrapper']}>
          {category && (
            <div className={styles.header__category}>
              <span
                style={{
                  color: categoryColor,
                }}
                title={category}
              >
                {category.toUpperCase()}
              </span>
            </div>
          )}
          {title && (
            <>
              {titleClassName ? (
                <div
                  className={classnames(styles.header__title, titleClassName)}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              ) : (
                <button type="button" className={styles.header__title} title={title} onClick={e => handleClick(e)}>
                  {title}
                </button>
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}

InformationCardHeader.propTypes = {
  category: PropTypes.string,
  categoryColor: PropTypes.string,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  icon: ICON,
  title: PropTypes.string.isRequired,
  titleClassName: PropTypes.string,
}

InformationCardHeader.defaultProps = {
  category: null,
  categoryColor: null,
  className: null,
  icon: null,
  titleClassName: undefined,
  handleClick: null,
}

export default InformationCardHeader
