/* eslint-disable react-hooks/exhaustive-deps */
import { isEmpty, isEqual } from 'lodash'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef } from 'react'

import { ACTIONS, I18N, INVITATION, LIST } from 'constants/props'
import { DECLINED, EXPIRED, PENDING_LIMITED_MEMBER, PENDING_PRO_MEMBER } from 'constants/invitationStatus'
import { INVITATION_STATUS_FILTERS } from 'constants/searchFilters'
import ActionButton from 'generics/ActionButton'
import Icon from 'generics/Icon'
import InfiniteScrollList from 'generics/InfiniteScrollList'
import ModalConfirmation from 'generics/ModalConfirmation'
import SearchBar from 'generics/SearchBar'
import Translation from 'generics/Translation'

import PeopleListItem from '../PeopleListItem'
import styles from './SentInvites.scss'

const SentInvites = ({
  i18n,
  accountId,
  accountActions,
  invitesActions,
  allowLimitedMember,
  onRenderLabel,
  onResendInvitation,
  sentInvitations,
}) => {
  const [deleteItem, setDeleteItem] = useState(null)
  const [filter, setFilter] = useState(null)
  const [filters, setFilters] = useState(null)
  const [isFetching, setIsFetching] = useState(null)
  const [isInvalidateInvitationModalOpen, setIsInvalidateInvitationModalOpen] = useState(false)

  let infiniteScrollList = useRef(null)

  const handleFetchRequests = (pageIndex = 1) => {
    if (pageIndex === 1) {
      infiniteScrollList.infiniteScrollRef.resetIndex()
    }

    return invitesActions.fetchSentInvitations({ pageIndex, search: filter, filters })
  }

  useEffect(() => {
    handleFetchRequests()
  }, [])

  const handleResendInvitation = (id, status) => {
    setIsFetching(id)
    onResendInvitation(id, status, () => {
      setIsFetching(null)
      accountActions.fetchAccount(accountId)
    })
  }

  const handleInvalidateInvitation = invitationId => {
    setDeleteItem(invitationId)
    setIsInvalidateInvitationModalOpen(true)
  }

  const handleInvalidateInvitationCancel = () => {
    setDeleteItem(undefined)
    setIsInvalidateInvitationModalOpen(false)
  }

  const handleInvalidateInvitationAccept = () => {
    setIsFetching(deleteItem)

    const onSuccess = () => {
      setIsFetching(null)
      setFilter(null)
      setFilters(null)
      setDeleteItem(null)
    }

    const onError = () => {
      setIsFetching(null)
    }

    invitesActions.fetchInvalidateInvitation(deleteItem, onSuccess, onError)

    setIsInvalidateInvitationModalOpen(false)
  }

  const handleFilterSearch = searchInput => {
    if (isEqual(filter, searchInput)) {
      return
    }

    setFilter(searchInput)
  }

  const handleFiltersSearch = otherFilters => {
    if (isEqual(filters, otherFilters)) {
      return
    }

    setFilters(otherFilters)
  }

  const handleSearch = (searchInput, otherFilters) => {
    handleFilterSearch(searchInput)
    handleFiltersSearch(otherFilters)
  }

  useEffect(() => {
    if (!deleteItem && deleteItem !== undefined) {
      handleFetchRequests(1)
    }
  }, [filter, filters, deleteItem])

  const renderList = () =>
    !isEmpty(sentInvitations.list) ? (
      sentInvitations.list.map(item => {
        const { id, status } = item

        const actionLabel = onRenderLabel(status)

        const canRemove =
          status === PENDING_PRO_MEMBER ||
          status === DECLINED ||
          status === EXPIRED ||
          status === PENDING_LIMITED_MEMBER

        const sentInvitationsActions = [
          <ActionButton
            actionType="decline"
            ariaLabel={`${item.name}, ${actionLabel}`}
            desktop={{
              props: {
                className: classnames(styles.resend__desktop, { [styles.resend__desktop__big_label]: !canRemove }),
                label: actionLabel,
                title: actionLabel,
              },
            }}
            isVisible={!!actionLabel}
            key={actionLabel}
            mobile={{
              props: {
                children: <Icon.Stroke7 className={styles.button__icon} name="refresh" />,
                className: classnames(styles.resend__mobile, { [styles.resend__mobile__big_label]: !canRemove }),
              },
            }}
            onClick={() => handleResendInvitation(id, status)}
          />,
        ]

        if (canRemove) {
          sentInvitationsActions.push(
            <Icon.Stroke7
              aria-label={`${item.name}, ${i18n.pageAdministration.peopleList.invalidateInvitation}`}
              className={styles.less}
              key={`invalidate-invitation-${item.id}`}
              name="less"
              onClick={() => {
                handleInvalidateInvitation(id)
              }}
              role="button"
              tabIndex={0}
              title={i18n.pageAdministration.peopleList.invalidateInvitation}
            />,
          )
        }

        return (
          <PeopleListItem
            actions={sentInvitationsActions}
            dateLabel={i18n.pageAdministration.peopleList.dateSent}
            isFetching={isFetching === id}
            item={item}
            key={`invitations-${id}`}
            listItemTestAttribute="invitations-list-item"
          />
        )
      })
    ) : (
      <div className={styles.empty}>
        <span>{i18n.pageAdministration.peopleList.noResultsToDisplayYetLabel}</span>
      </div>
    )

  const infiniteScroll = {
    hasMorePages: sentInvitations.meta.morePages,
    listHeight: 600,
    onFetch: handleFetchRequests,
  }

  return (
    <>
      <SearchBar
        className={styles.search}
        floatingLabelText={i18n.pageAdministration.sentInvites.search}
        onChange={searchInput => handleSearch(searchInput)}
        onSearch={(searchInput, otherFilters) => handleSearch(searchInput, otherFilters)}
        filters={[
          {
            filterLabel: 'status',
            label: i18n.pageAdministration.sentInvites.statusFilterLabel,
            options: INVITATION_STATUS_FILTERS(false, allowLimitedMember),
          },
        ]}
      />
      <InfiniteScrollList
        className={styles['scroll-list-main']}
        infiniteScroll={infiniteScroll}
        listId="sent-invites-scroll-list"
        innerRef={component => {
          infiniteScrollList = component
        }}
      >
        {renderList()}
      </InfiniteScrollList>
      {isInvalidateInvitationModalOpen && (
        <ModalConfirmation
          confirmationMessages={[i18n.pageAdministration.peopleList.invalidateInvitationConfirmationMessage]}
          barClassName={styles.bar}
          onAccept={handleInvalidateInvitationAccept}
          onCancel={handleInvalidateInvitationCancel}
        />
      )}
    </>
  )
}

SentInvites.propTypes = {
  i18n: I18N.isRequired,
  accountId: PropTypes.number.isRequired,
  accountActions: ACTIONS.isRequired,
  invitesActions: ACTIONS.isRequired,
  allowLimitedMember: PropTypes.bool.isRequired,
  sentInvitations: LIST(INVITATION).isRequired,
  onResendInvitation: PropTypes.func.isRequired,
  onRenderLabel: PropTypes.func.isRequired,
}

export default Translation(SentInvites, ['pageAdministration'])
