/* eslint-disable react-hooks/exhaustive-deps */
import { get, isEmpty, sortBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

import { ACTIONS, I18N, INVITATION_GROUP, INVITATION_GROUPS } from 'constants/props'
import { ARCHIVE_GROUP_SETTING } from 'constants/archiveGroup'
import { Invite } from 'classes'
import ActionButton from 'generics/ActionButton'
import FormGroupSearch from 'components/FormGroupSearch'
import Icon from 'generics/Icon'
import InfiniteScrollList from 'generics/InfiniteScrollList'
import ModalGroup from 'containers/ModalGroup'
import Translation from 'generics/Translation'

import GroupListItem from '../GroupListItem'
import ListPeopleGroupListHeader from '../ListPeopleGroupListHeader'
import PeopleListItem from '../PeopleListItem'
import styles from './Groups.scss'

const Groups = ({
  accountActions,
  accountId,
  currentGroup,
  groups,
  groupsActions,
  i18n,
  onRenderLabel,
  onResendInvitation,
}) => {
  const [initialValues, setInitialValues] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [isFetching, setIsFetching] = useState(null)
  const [searchInfo, setSearchInfo] = useState({ ...ARCHIVE_GROUP_SETTING })

  let infiniteScrollList = useRef(null)

  const handleFetchRequests = (pageIndex = 1) => {
    if (pageIndex === 1 && get(infiniteScrollList, 'infiniteScrollRef')) {
      infiniteScrollList.infiniteScrollRef.resetIndex()
    }
    return groupsActions.fetchGroups({ pageIndex, searchInfo })
  }

  useEffect(() => {
    handleFetchRequests()
  }, [])

  const handleSearchGroup = newSearchInfo => {
    setSearchInfo(newSearchInfo)
  }

  useEffect(() => {
    handleFetchRequests(1)
  }, [searchInfo])

  const handleSelectGroup = groupId => {
    setIsFetching(groupId)

    const onSuccess = () => {
      setIsFetching(null)
    }

    const onError = () => {
      setIsFetching(null)
    }

    groupsActions.fetchGroupDetails(groupId, onSuccess, onError)
  }

  const handleClickOnBackButton = () => {
    groupsActions.clearGroup()
    setInitialValues({ ...searchInfo })
    handleSearchGroup({ ...searchInfo })
  }

  const handleClickOnEditButton = () => {
    setIsEdit(true)
  }

  const handleCloseModal = () => {
    setIsEdit(false)
  }

  const handleResendInvitation = (id, status) => {
    setIsFetching(id)
    onResendInvitation(id, status, () => {
      setIsFetching(null)
    })
  }

  const renderList = () => {
    const list = currentGroup
      ? currentGroup.invites.map(item => {
          const { id, status } = item

          const actionLabel = onRenderLabel(status)

          const actions = [
            <ActionButton
              actionType="decline"
              desktop={{
                props: {
                  className: styles.resend__desktop,
                  label: actionLabel,
                  title: actionLabel,
                },
              }}
              isVisible={!!actionLabel}
              key={actionLabel}
              mobile={{
                props: {
                  children: <Icon.Stroke7 className={styles.button__icon} name="refresh" />,
                  className: styles.resend__mobile,
                },
              }}
              onClick={() => handleResendInvitation(item.id, item.status)}
            />,
          ]

          return (
            <PeopleListItem
              showEmail
              actions={actions}
              hasDate={false}
              isFetching={isFetching === id}
              item={new Invite(item)}
              key={`group-invites${id}`}
            />
          )
        })
      : sortBy(groups.list, ['name']).map(item => (
          <GroupListItem
            accountId={accountId}
            groupsActions={groupsActions}
            groupId={item.id}
            item={item}
            key={`groups-${item.id}`}
            isFetching={isFetching === item.id}
            onClick={() => {
              handleSelectGroup(item.id)
            }}
            selectedFilter={searchInfo.isArchive}
          />
        ))

    return !isEmpty(list) ? (
      list
    ) : (
      <div className={styles.empty}>
        <span>{i18n.pageAdministration.peopleList.noResultsToDisplayYetLabel}</span>
      </div>
    )
  }

  const groupInvitations = isEdit ? currentGroup.invites : undefined

  const infiniteScroll = {
    hasMorePages: groups.meta.morePages,
    listHeight: 600,
    onFetch: handleFetchRequests,
  }

  const onComplete = () => {
    handleFetchRequests()
    accountActions.fetchAccount(accountId)
  }

  return (
    <>
      {currentGroup && (
        <ListPeopleGroupListHeader
          group={currentGroup}
          onClickBackButton={handleClickOnBackButton}
          onClickEditButton={handleClickOnEditButton}
        />
      )}
      {!currentGroup && <FormGroupSearch initialValues={initialValues} onSearch={handleSearchGroup} />}
      <InfiniteScrollList
        className={styles['scroll-list-main']}
        infiniteScroll={infiniteScroll}
        listId="groups-scroll-list"
        innerRef={component => {
          infiniteScrollList = component
        }}
      >
        {renderList()}
      </InfiniteScrollList>
      {isEdit && <ModalGroup invitations={groupInvitations} onClose={handleCloseModal} onComplete={onComplete} />}
    </>
  )
}

Groups.propTypes = {
  accountActions: ACTIONS.isRequired,
  accountId: PropTypes.number.isRequired,
  currentGroup: INVITATION_GROUP,
  groups: INVITATION_GROUPS.isRequired,
  groupsActions: ACTIONS.isRequired,
  i18n: I18N.isRequired,
  onRenderLabel: PropTypes.func.isRequired,
  onResendInvitation: PropTypes.func.isRequired,
}

Groups.defaultProps = {
  currentGroup: null,
}

export default Translation(Groups, ['pageAdministration'])
