export const PEOPLE_FILTERS = hasDepartment => {
  let options = [
    { id: 'first_name', label: 'First Name' },
    { id: 'last_name', label: 'Last Name' },
    { id: 'email', label: 'Email' },
  ]

  if (hasDepartment) {
    options = [...options, { id: 'department', label: 'Department' }]
  }

  return options
}

export const TEAM_FILTERS = [
  { id: 'name', label: 'Name' },
  { id: 'description', label: 'Description' },
]

export const INVITATION_STATUS_FILTERS = (isMemberUpgradeRequest, allowLimitedMember = false) => {
  let options = [
    { id: 'default', label: 'All' },
    isMemberUpgradeRequest
      ? { id: 'pending', label: 'Pending' }
      : { id: 'pending_pro_member', label: 'Pending Pro Member' },
    { id: 'expired', label: 'Expired' },
    { id: 'declined', label: 'Declined' },
  ]

  if (isMemberUpgradeRequest) {
    options = [...options, { id: 'accepted', label: 'Accepted' }]
  } else if (allowLimitedMember) {
    options = [...options, { id: 'pending_limited_member', label: 'Pending Limited Member' }]
  }

  return options
}

export const DEFAULT_STATUS_FILTER = [
  {
    filterLabel: 'status',
    filterBy: INVITATION_STATUS_FILTERS(true)[1]?.id,
  },
]
