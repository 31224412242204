/* eslint-disable react-hooks/exhaustive-deps */
import { isEmpty, isEqual } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef } from 'react'

import { ACTIONS, I18N, LIST, PERSON } from 'constants/props'
import { DEFAULT_STATUS_FILTER, INVITATION_STATUS_FILTERS } from 'constants/searchFilters'
import InfiniteScrollList from 'generics/InfiniteScrollList'
import ModalConfirmation from 'generics/ModalConfirmation'
import SearchBar from 'generics/SearchBar'
import Translation from 'generics/Translation'

import PeopleListItem from '../PeopleListItem'
import styles from './UpgradeMembers.scss'

const UpgradeMembers = ({
  accountActions,
  currentProfile,
  i18n,
  invitesActions,
  isMultipleSelection,
  memberUpgradeRequests,
  selectedMembers,
  setSelectedMembers,
  setUpdatedRequests,
  updatedRequests,
  allowLimitedMember,
}) => {
  const [deleteItem, setDeleteItem] = useState(null)
  const [filter, setFilter] = useState(null)
  const [filters, setFilters] = useState(DEFAULT_STATUS_FILTER)
  const [isFetching, setIsFetching] = useState(null)
  const [isInvalidateInvitationModalOpen, setIsInvalidateInvitationModalOpen] = useState(false)
  const [isRendered, setIsRendered] = useState(false)

  let infiniteScrollList = useRef(null)

  const handleFetchRequests = (pageIndex = 1) => {
    if (pageIndex === 1) {
      infiniteScrollList.infiniteScrollRef.resetIndex()
    }

    return accountActions.fetchMemberUpgradeRequests({ pageIndex, search: filter, filters })
  }

  useEffect(() => {
    if (!isRendered) {
      handleFetchRequests()
      setIsRendered(true)
    }
  }, [])

  useEffect(() => {
    if (updatedRequests) {
      handleFetchRequests()
      setUpdatedRequests(false)
    }
  }, [updatedRequests])

  const handleInvalidateInvitationCancel = () => {
    setDeleteItem(undefined)
    setIsInvalidateInvitationModalOpen(false)
  }

  const handleInvalidateInvitationAccept = () => {
    setIsFetching(deleteItem)

    const onSuccess = () => {
      setIsFetching(null)
      setFilter(null)
      setFilters(null)
      setDeleteItem(null)
    }

    const onError = () => {
      setIsFetching(null)
    }

    invitesActions.fetchInvalidateInvitation(deleteItem, onSuccess, onError)

    setIsInvalidateInvitationModalOpen(false)
  }

  const handleFilterSearch = searchInput => {
    if (isEqual(filter, searchInput)) {
      return
    }

    setFilter(searchInput)
  }

  const handleFiltersSearch = otherFilters => {
    if (isEqual(filters, otherFilters)) {
      return
    }

    setFilters(otherFilters)
  }

  const handleSearch = (searchInput, otherFilters) => {
    handleFilterSearch(searchInput)
    handleFiltersSearch(otherFilters)
  }

  useEffect(() => {
    if (!deleteItem && deleteItem !== undefined) {
      handleFetchRequests(1)
    }
  }, [filter, filters, deleteItem])

  const renderList = () =>
    !isEmpty(memberUpgradeRequests?.list) ? (
      memberUpgradeRequests?.list.map(item => {
        const { id } = item

        const memberId = id

        return (
          <PeopleListItem
            currentProfile={currentProfile}
            dateLabel={i18n.pageAdministration.peopleList.dateSent}
            isChecked={selectedMembers && !!selectedMembers[memberId]}
            isFetching={isFetching === id}
            isMultipleSelection={isMultipleSelection && item.status === 'pending'}
            item={item}
            key={`invitations-${id}`}
            listItemTestAttribute="invitations-list-item"
            onChange={() =>
              setSelectedMembers({
                ...selectedMembers,
                [memberId]: selectedMembers[memberId] ? false : item,
              })
            }
          />
        )
      })
    ) : (
      <div className={styles.empty}>
        <span>{i18n.pageAdministration.peopleList.noResultsToDisplayYetLabel}</span>
      </div>
    )

  const infiniteScroll = {
    hasMorePages: memberUpgradeRequests?.meta?.morePages || false,
    listHeight: 600,
    onFetch: handleFetchRequests,
  }

  return (
    <>
      <SearchBar
        className={styles.search}
        floatingLabelText={i18n.pageAdministration.sentInvites.search}
        onChange={searchInput => handleSearch(searchInput)}
        onSearch={(searchInput, otherFilters) => handleSearch(searchInput, otherFilters)}
        filters={[
          {
            filterLabel: 'status',
            label: i18n.pageAdministration.sentInvites.statusFilterLabel,
            options: INVITATION_STATUS_FILTERS(true, allowLimitedMember).filter(option => option.id !== 'expired'),
            defaultValue: INVITATION_STATUS_FILTERS(true, allowLimitedMember)[1]?.id,
          },
        ]}
      />
      <InfiniteScrollList
        className={styles['scroll-list-main']}
        infiniteScroll={infiniteScroll}
        listId="sent-invites-scroll-list"
        innerRef={component => {
          infiniteScrollList = component
        }}
      >
        {renderList()}
      </InfiniteScrollList>
      {isInvalidateInvitationModalOpen && (
        <ModalConfirmation
          confirmationMessages={[i18n.pageAdministration.peopleList.invalidateInvitationConfirmationMessage]}
          barClassName={styles.bar}
          onAccept={handleInvalidateInvitationAccept}
          onCancel={handleInvalidateInvitationCancel}
        />
      )}
    </>
  )
}

UpgradeMembers.propTypes = {
  accountActions: ACTIONS.isRequired,
  currentProfile: PERSON.isRequired,
  i18n: I18N.isRequired,
  invitesActions: ACTIONS.isRequired,
  isMultipleSelection: PropTypes.bool,
  memberUpgradeRequests: LIST.isRequired,
  selectedMembers: PropTypes.shape({}),
  setSelectedMembers: PropTypes.func,
  setUpdatedRequests: PropTypes.func.isRequired,
  updatedRequests: PropTypes.bool.isRequired,
  allowLimitedMember: PropTypes.bool.isRequired,
}

UpgradeMembers.defaultProps = {
  isMultipleSelection: false,
  selectedMembers: {},
  setSelectedMembers: () => {},
}

export default Translation(UpgradeMembers, ['pageAdministration'])
