import { autobind } from 'core-decorators'
import { bindActionCreators } from 'redux'
import { Col, Container, Row } from 'reactstrap'
import { connect } from 'react-redux'
import { find, get, reject } from 'lodash'
import { formatRoute } from 'react-router-named-routes'
import { sprintf } from 'sprintf-js'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
  ACTIONS,
  ASSESSMENT_SCORES,
  CUSTOMIZATIONS,
  HISTORY,
  I18N,
  MATCH,
  MODULES,
  PERSON,
  TEAM,
} from 'constants/props'
import { MY_5_DYNAMICS, NETWORK_DASHBOARD, NETWORK, TEAMS_DASHBOARD, TEAMS } from 'urls'
import { NOTES_SECTION } from 'constants/notesSection'
import { OVERVIEW_REPORT_MODULE } from 'constants/customizationModules'
import { Person } from 'classes'
import { uppercaseFirstLetter } from 'helpers'
import * as accountSelectors from 'app_modules/accounts/selectors'
import * as networkActions from 'app_modules/network/actions'
import * as networkSelectors from 'app_modules/network/selectors'
import * as sessionActions from 'app_modules/session/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import * as teamsActions from 'app_modules/teams/actions'
import * as teamSelectors from 'app_modules/teams/selectors'
import api from 'api'
import Breadcrumbs from 'generics/Breadcrumbs'
import Module from 'components/Module'
import PageCategories from 'containers/PageCategories'
import PageHeader from 'generics/PageHeader'
import ProfileNotes from 'generics/ProfileNotes'
import Translation from 'generics/Translation'

import styles from './PageProfileByContext.scss'

const { PUBLIC_PATH } = process.env

const PROCESS_IMPROVEMENT_IMAGE = `${window.location.origin}${PUBLIC_PATH}process-improvement.png`

class ProfileByContext extends Component {
  constructor(props) {
    super(props)

    this.state = {
      _profile0: null,
      _profile1: null,
      documents: [],
      isFetchingDocuments: false,
      searchResults: [[], []],
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { actions, history, match } = this.props

    const { contentType, context, contextId } = match.params

    switch (contentType) {
      case 'individual':
        switch (context) {
          case undefined:
            this.handleSetDocuments()

            break

          case 'user':
            actions.fetchThirdPersonProfile({
              contentType,
              profileId: contextId,
            })

            this.handleSetDocuments({ profileId: contextId })

            break

          default:
            history.go(-1)
        }
        break

      case 'pair':
        switch (context) {
          case 'user':
            actions.fetchThirdPersonProfile({
              contentType: 'individual',
              profileId: contextId,
            })

            this.handleSetDocuments({ profileId: contextId })

            break

          case 'team':
            actions.fetchTeamDashboard({
              contentType: 'team',
              includeAllMembers: true,
              teamId: contextId,
            })

            break

          default:
            history.go(-1)
        }
        break

      case 'team':
      case 'role':
        switch (context) {
          case 'team':
            actions.fetchTeamDashboard({
              contentType: 'team',
              teamId: contextId,
            })

            this.handleSetDocuments({ teamId: contextId })

            break

          default:
            history.go(-1)
        }

        break

      default:
        history.go(-1)
    }
  }

  @autobind
  getRenderInfo() {
    const { match, secondPerson, team, thirdPerson } = this.props

    const { _profile0, _profile1 } = this.state

    const { accountId, contentType, context } = match.params

    const breadcrumbs = []
    let header = []
    let modules = []
    let profile0
    let profile1
    let profiles = []
    let useAutoComplete = false

    switch (contentType) {
      case 'individual':
        switch (context) {
          case undefined:
            ;({ modules } = secondPerson)

            profile0 = new Person(secondPerson.profile)

            profile0.legend = secondPerson.profile.jobTitle

            breadcrumbs.push({
              label: 'My 5 Dynamics',
              to: formatRoute(MY_5_DYNAMICS, { accountId }),
            })

            break

          case 'user':
            ;({ modules } = thirdPerson)

            profile0 = new Person(thirdPerson.profile)

            profile0.legend = thirdPerson.profile.jobTitle

            breadcrumbs.push({
              label: 'Network',
              to: formatRoute(NETWORK, { accountId }),
            })

            if (profile0.account) {
              breadcrumbs.push({
                label: profile0.firstName,
                to: formatRoute(NETWORK_DASHBOARD, {
                  accountId,
                  contentType: 'individual',
                  profileId: profile0.id,
                }),
              })
            }

            break

          default:
        }

        header = [profile0]
        profiles = header

        break

      case 'pair':
        switch (context) {
          case 'user':
            ;({ modules } = thirdPerson)

            profile0 = new Person(secondPerson.profile)

            profile1 = new Person(thirdPerson.profile)

            header = [profile0, profile1]

            profiles = header

            breadcrumbs.push({
              label: 'Network',
              to: formatRoute(NETWORK, { accountId }),
            })

            if (profile1.account) {
              breadcrumbs.push({
                label: profile1.firstName,
                to: formatRoute(NETWORK_DASHBOARD, {
                  accountId,
                  contentType: 'individual',
                  profileId: profile1.id,
                }),
              })
            }

            break

          case 'team': {
            ;({ modules } = team)

            const { searchResults } = this.state

            useAutoComplete = true

            profile0 = {
              id: '0',
              onChange: id => {
                this.handleChangeEntity({ profile0: id })
              },
              onSearch: (filter, onSuccess) => {
                this.handleSearchEntity(filter, onSuccess, 0)
              },
              profiles: searchResults[0],
            }

            profile1 = {
              id: '1',
              onChange: id => {
                this.handleChangeEntity({ profile1: id })
              },
              onSearch: (filter, onSuccess) => {
                this.handleSearchEntity(filter, onSuccess, 1)
              },
              profiles: searchResults[1],
            }

            breadcrumbs.push({
              label: 'Teams',
              to: formatRoute(TEAMS, { accountId }),
            })

            if (team) {
              breadcrumbs.push({
                label: team.name,
                to: formatRoute(TEAMS_DASHBOARD, {
                  accountId,
                  teamId: team.id,
                }),
              })
            }

            header = [profile0, profile1]

            profiles = [
              find(searchResults[0], { id: _profile0 }) || new Person(),
              find(searchResults[1], { id: _profile1 }) || new Person(),
            ]

            break
          }

          default:
            return null
        }
        break

      case 'team':
        switch (context) {
          case 'team':
            ;({ modules } = team)

            profile0 = team

            breadcrumbs.push({
              label: 'Teams',
              to: formatRoute(TEAMS, { accountId }),
            })

            if (profile0.account) {
              breadcrumbs.push({
                label: profile0.name,
                to: formatRoute(TEAMS_DASHBOARD, {
                  accountId,
                  teamId: team.id,
                }),
              })
            }

            header = [profile0]
            profiles = header

            break

          default:
        }

        break

      case 'role':
        switch (context) {
          case 'team':
            ;({ modules } = team)

            profile0 = new Person(secondPerson.profile)

            profile1 = team

            breadcrumbs.push({
              label: 'Teams',
              to: formatRoute(TEAMS, { accountId }),
            })

            if (profile1.account) {
              breadcrumbs.push({
                label: profile1.name,
                to: formatRoute(TEAMS_DASHBOARD, {
                  accountId,
                  teamId: team.id,
                }),
              })
            }

            header = [profile0, profile1]

            profiles = header

            break

          default:
        }
        break

      default:
    }

    return {
      breadcrumbs,
      modules,
      profiles,
      header,
      useAutoComplete,
    }
  }

  @autobind
  handleSetDocuments(options) {
    const { actions, match } = this.props

    const { accountId, contentType, moduleId } = match.params

    this.setState({ isFetchingDocuments: true })

    const onSuccess = documents => {
      const { customizations, i18n } = this.props

      const { header } = this.getRenderInfo()

      const newDocuments = [...documents]
      if (NOTES_SECTION[moduleId] && options) {
        newDocuments.push({
          documentKey: i18n.notes.title.toLowerCase(),
          matches: [
            {
              content: (
                <ProfileNotes
                  accountId={accountId}
                  name={`${header[0].firstName} ${header[0].lastName}`}
                  profileId={options.profileId}
                />
              ),
              isCustom: true,
            },
          ],
          title: i18n.notes.yourNotestitle,
        })
      }
      if (moduleId === 'work_preferences_1' && customizations[OVERVIEW_REPORT_MODULE].overviewRptIntro && !options) {
        newDocuments.unshift({
          documentKey: i18n.customizations.overviewReport.introduction.toLowerCase(),
          matches: [
            {
              content: `<p>${customizations[OVERVIEW_REPORT_MODULE].overviewRptIntro}</p>`,
              title: i18n.customizations.overviewReport.introduction,
            },
          ],
          title: i18n.customizations.overviewReport.introduction,
        })
      }
      this.setState({
        documents: newDocuments,
        isFetchingDocuments: false,
      })
    }

    const onError = () => {
      this.setState({
        isFetchingDocuments: false,
      })
    }

    actions.fetchDocuments({ contentType, moduleId, ...options }, onSuccess, onError)
  }

  @autobind
  handleChangeEntity(value) {
    const { match, secondPerson } = this.props

    const { context, contextId } = match.params

    let { _profile0, _profile1 } = this.state

    const teamId = context === 'team' && contextId ? contextId : undefined

    if ('profile0' in value) {
      _profile0 = value.profile0
    }

    if ('profile1' in value) {
      _profile1 = value.profile1
    }

    if (_profile0 && _profile1) {
      this.setState({
        _profile0,
        _profile1,
      })

      if (_profile0 === secondPerson.profile.id) {
        this.handleSetDocuments({
          profileId: _profile1.toString(),
          teamId,
        })
      } else {
        this.handleSetDocuments({
          profiles: [_profile0, _profile1],
          teamId,
        })
      }
    } else {
      this.setState({
        _profile0,
        _profile1,
        documents: [],
      })
    }
  }

  handleSearchEntity(filter, onFinish, index) {
    const { match, team } = this.props

    const { accountId } = match.params

    const { id: teamId } = team

    this.setState({ [`_profile${index}`]: null })

    const onSuccess = ({ profiles = [] }) => {
      this.setState(({ searchResults }) => {
        const { _profile0, _profile1 } = this.state

        const id = index === 0 ? _profile1 : _profile0
        const newProfiles = id ? reject(profiles, { id }) : profiles

        const newSearchResults = searchResults
        newSearchResults[index] = newProfiles.map(profile => new Person(profile))

        return {
          searchResults: newSearchResults,
        }
      })

      onFinish()
    }

    api.teams.getTeamMembers({ accountId: parseInt(accountId, 10), pageIndex: 0, search: filter, teamId }, onSuccess)
  }

  render() {
    const { i18n, match, team, thirdPerson } = this.props

    const { documents, isFetchingDocuments } = this.state

    const { contentType, context, moduleId } = match.params

    const isFetchingHeader = team.isFetching || thirdPerson.isFetching

    const { useAutoComplete, breadcrumbs, header, modules, profiles } = this.getRenderInfo()

    const module = modules.find(item => item.key === moduleId)

    let categoryColor

    // debugger
    if (module) {
      ;({ categoryColor } = module)

      if (module.categoryTitle) {
        breadcrumbs.push({
          label: uppercaseFirstLetter(module.categoryTitle.replace(/-/g, ' ')),
        })
      }

      // This should be handled from EPIC
      if (get(module, 'key', '').includes('project_completion_cycle')) {
        module.image = PROCESS_IMPROVEMENT_IMAGE
      } else if (module.key === 'leading___name_s_12') {
        module.title =
          contentType === 'pair' && context === 'team'
            ? i18n.generics.leadershipModuleTitle
            : sprintf(module.title, { name: profiles[1].name })
      }
    }

    return (
      <div className={styles.page}>
        <Container>
          <Row>
            <Col>
              <Module
                barBackgroundColor={categoryColor}
                breadcrumbs={<Breadcrumbs className={styles.breadcrumbs} linkList={breadcrumbs} />}
                context={context}
              >
                <PageHeader
                  chartData={profiles}
                  className={styles.header}
                  data={header}
                  isFetching={isFetchingHeader}
                  module={module}
                  useAutoComplete={useAutoComplete}
                />

                <PageCategories documents={documents} isFetching={isFetchingDocuments} title={module?.title} />
              </Module>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

ProfileByContext.propTypes = {
  actions: ACTIONS.isRequired,
  customizations: CUSTOMIZATIONS.isRequired,
  history: HISTORY.isRequired,
  i18n: I18N.isRequired,
  match: MATCH.isRequired,
  secondPerson: PropTypes.shape({
    modules: MODULES.isRequired,
    profile: PERSON.isRequired,
    scores: ASSESSMENT_SCORES.isRequired,
  }),
  team: TEAM,
  thirdPerson: PropTypes.shape({
    modules: MODULES.isRequired,
    profile: PERSON.isRequired,
    isFetching: PropTypes.bool,
  }),
}

ProfileByContext.defaultProps = {
  secondPerson: {},
  team: {},
  thirdPerson: {},
}

const mapStateToProps = state => ({
  accountId: sessionSelectors.accountId(state),
  customizations: accountSelectors.customizations(state),
  secondPerson: {
    modules: sessionSelectors.modules(state),
    profile: sessionSelectors.currentProfile(state),
    scores: sessionSelectors.assessmentScores(state),
  },
  thirdPerson: {
    isFetching: networkSelectors.thirdPerson.isFetching(state),
    modules: networkSelectors.thirdPerson.modules(state),
    profile: networkSelectors.thirdPerson.profile(state),
  },
  team: teamSelectors.team(state),
})

const actions = {
  ...networkActions,
  ...sessionActions,
  ...teamsActions,
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Translation(ProfileByContext, ['customizations', 'generics', 'notes'])),
)
