/**
 * @description Settings for groups controller
 * @module API controller / Groups
 */
import moment from 'moment'

module.exports = {
  archiveGroup: {
    /**
     * @description Get request settings (group endpoint - updateGroup)
     * @param {object} options
     * @param {number} options.accountId
     * @param {number} options.frequency
     * @param {number} options.frequencyType
     * @param {number} options.groupId
     * @param {string} options.name
     * @returns {object} request settings
     */
    settings: ({ accountId, archive, groupId, name }) => ({
      endpoint: `/account/${accountId}/groups/${groupId}`,
      body: {
        archive,
        name,
      },
      method: 'PATCH',
    }),
  },
  getGroupDetails: {
    /**
     * @description Get request settings (group endpoint - getGroupDetails)
     * @param {object} options
     * @param {number} options.accountId
     * @param {number} options.groupId
     * @returns {object} request settings
     */
    settings: ({ accountId, groupId }) => ({
      endpoint: `/account/${accountId}/groups/${groupId}`,
      method: 'GET',
    }),
  },

  getGroups: {
    ignoreNotFound: true,

    /**
     * @description Get request settings (group endpoint - getGroups)
     * @param {object} options
     * @param {number} options.accountId
     * @param {number} options.pageIndex
     * @param {date} options.endDate
     * @param {string} options.searchCriteria
     * @param {date} options.startDate
     * @returns {object} request settings
     */
    settings({ accountId, isArchive, pageIndex, endDate, searchCriteria, startDate }) {
      const formatDate = date => moment(date).utc().format('YYYY-MM-DD')
      const search = searchCriteria ? searchCriteria.trim() : null

      const params = []

      let endpoint = `/account/${accountId}/groups/search/`

      if (isArchive !== 'all') {
        endpoint += `${isArchive}/`
      }

      if (search) {
        params.push(`search_criteria=${search}`)
      }

      if (startDate) {
        params.push(`start_date=${formatDate(startDate)}`)
      }

      if (endDate) {
        params.push(`end_date=${formatDate(endDate)}`)
      }

      if (pageIndex) {
        params.push(`page=${pageIndex}`)
      }

      return {
        endpoint: encodeURI(`${endpoint}?${params.join('&')}`),
        method: 'GET',
      }
    },
  },

  updateGroup: {
    /**
     * @description Get request settings (group endpoint - updateGroup)
     * @param {object} options
     * @param {number} options.accountId
     * @param {number} options.frequency
     * @param {number} options.frequencyType
     * @param {number} options.groupId
     * @param {string} options.name
     * @returns {object} request settings
     */
    settings: ({ accountId, frequency, frequencyType, groupId, name }) => ({
      endpoint: `/account/${accountId}/groups/${groupId}`,
      body: {
        frequency,
        frequencyType,
        name,
      },
      method: 'PATCH',
    }),
  },

  updateGroupInvitations: {
    /**
     * @description Add or Remove group invitations fetch
     * @param {object} options
     * @param {number} options.accountId
     * @param {number} options.groupId
     * @param {array} options.invites
     * @param {array} options.removeInvites
     * @returns {object} request settings
     */
    settings: ({ accountId, groupId, invites, removeInvites }) => ({
      endpoint: `/account/${accountId}/groups/${groupId}/update_invites_for_groups`,
      body: {
        invites,
        removeInvites,
      },
      method: 'PATCH',
    }),
  },

  updateGroupInvitationEmails: {
    /**
     * @description gets request settings - Update group invitations emails fetch
     * @param {object} options
     * @param  {number} options.accountId
     * @param  {number} options.groupId
     * @param  {array} options.inviteEmails
     * @returns {object} request settings
     */
    settings: ({ accountId, groupId, inviteEmails }) => ({
      endpoint: `/account/${accountId}/groups/${groupId}/update_invites_email`,
      body: { inviteEmails },
      method: 'PATCH',
    }),
  },
}
